export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    roles: ['admin', 'customer'],
  },
  {
    title: 'Walleat Pay',
    route: 'walleat-pay',
    icon: 'CpuIcon',
    roles: ['admin'],
  },
  {
    title: 'Comisiones',
    icon: 'PercentIcon',
    roles: ['admin', 'establishment_admin'],
    children: [
      {
        title: 'Comisiones generales',
        route: 'emida-commission-general',
        roles: ['admin'],
      },
      {
        title: 'Comisiones pendientes de pago',
        route: 'emida-commission-instances',
        roles: ['admin', 'establishment_admin'],
      },
      {
        title: 'Reporte recargas y PS',
        route: 'emida-recharges-report',
        roles: ['admin', 'establishment_admin'],
      },
    ],
  },
  {
    title: 'Usuarios',
    route: 'apps-users-list',
    icon: 'UserIcon',
    roles: ['admin'],
  },
  {
    title: 'Categorías',
    route: 'categories-list',
    icon: 'BookmarkIcon',
    roles: ['admin'],
  },
  {
    title: 'Proveedores',
    route: 'suppliers-list',
    icon: 'TruckIcon',
    roles: ['admin'],
  },
  {
    title: 'Productos ',
    icon: 'PackageIcon',
    roles: ['admin'],
    children: [
      {
        title: 'Lista',
        route: 'ecommerce',
        roles: ['admin'],
      },
      {
        title: 'Administrar',
        route: 'products-list',
        roles: ['admin'],
      },
    ],
  },
  {
    title: 'Walleats',
    route: 'walleats-list',
    icon: 'DollarSignIcon',
    roles: ['admin', 'customer'],
  },
  {
    title: 'Establecimientos',
    route: 'establishments-list',
    icon: 'ShoppingBagIcon',
    roles: ['admin'],
  },
  {
    title: 'Comprar en linea',
    route: 'product-categories-list',
    icon: 'ShoppingBagIcon',
    roles: ['customer'],
  },
  {
    title: 'POS',
    route: 'selectPOS',
    icon: 'ShoppingBagIcon',
    roles: ['store_clerk'],
  },
  {
    title: 'Entregas',
    route: 'deliveries',
    icon: 'TruckIcon',
    roles: ['dispatcher'],
  },
  {
    title: 'Ayuda',
    icon: 'HelpCircleIcon',
    route: 'help-videos',
    roles: ['admin', 'establishment_admin', 'store_clerk'],
  },
]
