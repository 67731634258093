<template>
  <div
    id="menu-bottom-menu"
    class="d-flex justify-content-around align-items-center menu-shadow"
  >
    <b-link
      v-ripple
      :to="{ name: 'home' }"
    >
      <feather-icon
        size="24"
        icon="UserIcon"
      />
    </b-link>
    <b-link
      v-ripple
      class="online-shopping-icon text-light"
      :to="{ name: 'product-categories-list' }"
    >
      <feather-icon
        size="24"
        icon="ShoppingBagIcon"
      />
    </b-link>
    <b-link
      v-ripple
      class="shopping-cart-icon text-light"
      @click="openFinishPurchaseModal"
    >
      <feather-icon
        size="24"
        icon="ShoppingCartIcon"
      />
    </b-link>
    <b-link
      v-ripple
      :to="{ name: 'walleats-list' }"
    >
      <feather-icon
        size="24"
        icon="DollarSignIcon"
      />
    </b-link>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex';

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
  },

  computed: {
    ...mapGetters('pos', [
      'cartTotalProducts'
    ]),
  },

  methods: {

    openFinishPurchaseModal() {
      if (this.cartTotalProducts > 0) {
        this.$bvModal.show('finish-purchase')
      } else {
        this.$swal({
          text: 'No hay productos en el carrito',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      
    },
  },
}


</script>

<style lang="scss" scoped>
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

#menu-bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  background: white;
  box-shadow: 0 4px 24px 0 rgba(#000, 0.75);
  z-index: 10;
}
.online-shopping-icon {
  $size: 55px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 24px 0 rgba(#000, 0.5);
  align-items: center;
  background: $success;
  margin-bottom: 2rem;
  width: $size;
  height: $size;
  border-radius: 2rem;
}
.shopping-cart-icon {
  $size: 55px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 24px 0 rgba(#000, 0.5);
  align-items: center;
  background: $warning;
  margin-bottom: 2rem;
  width: $size;
  height: $size;
  border-radius: 2rem;
}
</style>
