<template>
  <li
    v-if="
      userData &&
      canViewVerticalNavMenuLink(item) &&
      item.roles.includes(userData.role_name)
    "
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
    @click="handleClick(item)"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ item.title }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl"
import { BLink, BBadge } from "bootstrap-vue"
import { useUtils as useI18nUtils } from "@core/libs/i18n"
import { getUserData } from "@/auth/utils"
import useVerticalNavMenuLink from "./useVerticalNavMenuLink"
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink"
import { mapActions, mapMutations } from "vuex"

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    )
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  data() {
    return {
      userData: {},
    }
  },
  created() {
    this.userData = getUserData()
  },
  methods: {
    ...mapActions("establishments", ["fetchEstablishments", "setStores"]),
    ...mapActions("pos", ["emptyCart"]),
    ...mapMutations("establishments", [
      "setOnlineStores",
      "setEmptyOnlineStores",
      "setStores",
    ]),
    ...mapMutations("pos", ["deleteReferenceToBook"]),

    handleClick(item) {
      if (
        item.route == "select-online-groceries-store" ||
        item.route == "select-online-book-store"
      ) {
        this.emptyCart()
        this.setEmptyOnlineStores()
        this.deleteReferenceToBook()
        this.fetchEstablishments({ by_type: this.$route.meta.storeType }).then(
          (response) => {
            response.data.forEach((element) => {
              element.stores_attributes.forEach((store) => {
                this.setStores(store)
                if (store.category === "online" || store.category === "hybrid")
                  this.setOnlineStores(store)
              })
            })
          }
        )
      }
    },
  },
}
</script>
